/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface SeoProps {
  title: string;
  meta?: Array<any>;
  description?: string;
}

const SEO = ({ description, meta, title }: SeoProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            twitterUsername
          }
        }
      }
    `
  );

  const image =
    'https://images.ctfassets.net/11q5musi1a1o/3Iu8b2xdvgYDeOW3YQcepg/294f396802ddb99b77f351e0afc6bf36/news-fallback.png';

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <meta name="description" content={site.siteMetadata.description} />
      <meta name="image" content={image} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={site.siteMetadata.siteUrl} />
      <meta property="og:title" content={site.siteMetadata.title} />
      <meta property="og:description" content={site.siteMetadata.description} />
      <meta property="og:image" content={image} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:creator"
        content={site.siteMetadata.twitterUsername}
      />
      <meta name="twitter:title" content={site.siteMetadata.title} />
      <meta
        name="twitter:description"
        content={site.siteMetadata.description}
      />
      <meta name="twitter:image" content={image} />
      <meta
        name="google-site-verification"
        content="Oh9waoGJd4aYG2oJcbKRdUTuPuBMcjsPjXMWbaKyYwc"
      />
    </Helmet>
  );
};

export default SEO;
